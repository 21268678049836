import { MuiThemeProvider } from '@material-ui/core/styles';
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { ConnectedRouter } from 'connected-react-router';
import { URIQuoteRedirect } from './URIQuoteRedirect';
import { History, UnregisterCallback } from 'history';
import UpdateRequiredModal from 'organisms/UpdateRequiredModal/update.wrap';
import InFlightUpdatesModal from 'organisms/UpdatesInFlightModal';
import UpstreamLoadingModal from 'organisms/UpstreamLoadingModal';
import React from 'react';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { Route } from 'react-router';
import { Store } from 'redux';
import { Persistor } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { instrumentation } from 'services/instrumentation';
import { setupTranslations } from 'services/translations';
import { muiTheme } from 'stylesheet';
import { ConnectedCurrencyProvider } from './context/Currency';
import { AcceptedLanguages, LanguageConsumer, LanguageProvider } from './context/Language';
import AppCrashFallback from './molecules/AppCrashFallback';
import ErrorBoundary from './molecules/ErrorBoundary';
import Routes from './routes';

interface IProps {
  history: History;
  persistor: Persistor;
  store: Store;
}

interface IState {
  locale: AcceptedLanguages;
}

const locales = setupTranslations();

// By default ChartDataLabels registers itself for all charts
Chart.plugins.unregister(ChartDataLabels);

class App extends React.PureComponent<IProps, IState> {
  state: IState = { locale: (localStorage.getItem('locale') as AcceptedLanguages) || 'en' };
  setLocale = (locale: AcceptedLanguages) => {
    localStorage.setItem('locale', locale);
    this.setState({ locale });
  };

  unlisten?: UnregisterCallback;

  wentOffline() {
    instrumentation.sendEvent('wentOffline');
  }

  cameOnline() {
    instrumentation.sendEvent('cameOnline');
  }

  routeChanged() {
    instrumentation.sendEvent('routeChanged');
  }

  componentDidMount() {
    this.unlisten = this.props.history.listen(this.routeChanged);
    window.addEventListener('online', this.cameOnline);
    window.addEventListener('offline', this.wentOffline);
  }

  componentWillUnmount() {
    this.unlisten && this.unlisten();
    window.removeEventListener('online', this.cameOnline);
    window.removeEventListener('offline', this.wentOffline);
  }

  render() {
    const { history, persistor, store } = this.props;

    return (
      <ErrorBoundary FallbackComponent={AppCrashFallback}>
        <LanguageProvider
          value={{
            locale: this.state.locale,
            setLocale: this.setLocale,
          }}
        >
          <LanguageConsumer>
            {({ locale }) => (
              <IntlProvider locale={locale} messages={locales[locale]}>
                <Provider store={store as any}>
                  <MuiThemeProvider theme={muiTheme}>
                    <PersistGate loading={null} persistor={persistor}>
                      <ConnectedCurrencyProvider>
                        <UpstreamLoadingModal />
                        <InFlightUpdatesModal />
                        <UpdateRequiredModal />
                        <ConnectedRouter history={history}>
                          <URIQuoteRedirect>
                            <Route path="/" component={Routes} />
                          </URIQuoteRedirect>
                        </ConnectedRouter>
                      </ConnectedCurrencyProvider>
                    </PersistGate>
                  </MuiThemeProvider>
                </Provider>
              </IntlProvider>
            )}
          </LanguageConsumer>
        </LanguageProvider>
      </ErrorBoundary>
    );
  }
}

export default App;
