export const PROPERTY_TYPE_UPSTREAM_VALUES = {
  holidayApartement: {
    translationKey: 'propertyTypeUpstream.holidayApartment',
    value: 'Holiday apartment',
  },
  holidayHouse: { translationKey: 'propertyTypeUpstream.holidayHouse', value: 'Holiday house' },
  semiDetachedHouse: {
    translationKey: 'propertyTypeUpstream.semiDetachedHouse',
    value: 'Semi detached House',
  },
};

export const HOLIDAY_APARTEMENT = PROPERTY_TYPE_UPSTREAM_VALUES.holidayApartement.value;
export const FORMER_FARM_HOUSE = 'Former farm house';
export const SEMI_DETACHED = 'Semi-Detached';
export const TERRACED_HOUSE = 'Terraced house';
export const HOLIDAY_COTTAGE = 'Holiday home';

export const PROPERTY_TYPE_OPTIONS = [
  { label: 'propertyType.holidayCottage', value: HOLIDAY_COTTAGE },
  { label: 'propertyType.terracedHouse', value: TERRACED_HOUSE },
  { label: 'propertyType.semiDetached', value: SEMI_DETACHED },
  { label: 'propertyType.holidayApartment', value: HOLIDAY_APARTEMENT },
  { label: 'propertyType.formerFarmHouse', value: FORMER_FARM_HOUSE },
];
