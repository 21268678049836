import { QuoteStatus } from 'constants/quotes';
import Dexie from 'dexie';
import { INewPropertyQuote } from 'services/appDatabase';
import withIndexedDB from './withIndexedDB';

export default (filterDeleted = false) => {
  const filter = (table: Dexie.Table<INewPropertyQuote, string>) => {
    const collection = filterDeleted
      ? table.where('status').notEqual(QuoteStatus.DELETED)
      : table.toCollection();
    return collection.reverse();
  };

  return withIndexedDB<INewPropertyQuote>('newProperties', { filter, sortBy: 'updatedAt' });
};
