import withNewProperties from 'hoc/withNewProperties';
import React, { FC } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import { INewPropertyQuote } from 'services/appDatabase';
import { QuoteState } from 'stateManagement/Quote';
import quote from 'stateManagement/Quote/actions';
import { getRawQuote } from 'stateManagement/Quote/selectors';
import { RootState } from 'stateManagement/types';

interface Props {
  quote: QuoteState;
  newProperties: INewPropertyQuote[];
  loadQuote: (property: INewPropertyQuote) => void;
  fetchAndLoadQuote: (contractNumber: string) => void;
  location: any;
}

const UnconnectedURIQuoteRedirect: FC<Props> = (props) => {
  const { newProperties, children, location, quote, loadQuote, fetchAndLoadQuote } = props;
  const quoteContractNumber = quote.contractNumber;

  const query = new URLSearchParams(location.search);
  const uriContractNumber = query.get('contractNumber');

  const uiUninitialized = !newProperties || newProperties.length === 0;

  if (!uriContractNumber || uriContractNumber === quoteContractNumber || uiUninitialized) {
    return <>{children}</>;
  }

  const property = newProperties.find((p) => p.contractNumber === uriContractNumber);

  if (property) {
    loadQuote(property);
  } else {
    fetchAndLoadQuote(uriContractNumber);
  }

  return <>{children}</>;
};

const mapStateToProps = (state: RootState) => ({
  quote: getRawQuote(state),
});

const mapDispatchToProps = {
  loadQuote: quote.loadQuote,
  fetchAndLoadQuote: quote.fetchAndLoadQuote,
};

export const URIQuoteRedirect = compose<any, any>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withNewProperties(false), // Get all properties (including deleted)
)(UnconnectedURIQuoteRedirect);
