export enum QuoteStatus {
  POPULATING = 'POPULATING',
  DRAFT = 'DRAFT',
  SUBMITTED = 'SUBMITTED',
  DELETED = 'DELETED',
  CLOSED = 'CLOSED',
}

export enum QuoteQueueStatus {
  QUEUED = 'queued',
  UPLOADING = 'uploading',
  UPLOADED = 'uploaded',
  FAILED = 'failed',
}
