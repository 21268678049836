export const COUNTRY_CODES = {
  DENMARK: '208',
  GERMANY: '280',
  MARKUP_0: '0',
};

export const MARKUP_COUNTRY_FALLBACK = {
  EURO: COUNTRY_CODES.GERMANY,
  DANISH_SALES_MARKET: COUNTRY_CODES.GERMANY,
  CASCADE: COUNTRY_CODES.MARKUP_0,
  INSURANCE: COUNTRY_CODES.GERMANY,
  SEASONAL_EXTRA: COUNTRY_CODES.GERMANY,
};

export const MARKET_SIZE = {
  MAJOR: 'Major',
  MID: 'Mid',
};
